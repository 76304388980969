.general-info-container {
    font-family: 'Arial', sans-serif;
    padding: 20px;
    border: 1px solid #ddd;
    background-color: #f8f8f8;
    margin: 20px;
  }
  
  .edit-mode {
    margin-bottom: 20px;
  }
  
  .edit-textarea {
    margin-top: 20px;
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    resize: vertical;
    height: 500px;
  }
  
  .hyperlink-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
  }
  
  .add-hyperlink-button {
    background-color: #4caf50;
    color: white;
    padding: 10px;
    border: none;
    cursor: pointer;
  }
  
  .add-hyperlink-button:hover {
    background-color: #45a049;
  }
  
  .preview-text {
    color: #333;
    font-size: 16px;
  }
  