body {
  margin: 0;
  font-family: 'Arial', sans-serif;
  background-color: #f5f5dc;
}

.container {
  max-width: 800px;
  margin: 20px auto;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.tabs {
  background-color: #072e09;
  overflow: hidden;
}

.tabs a {
  float: left;
  color: #fff;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 18px;
  transition: background-color 0.3s; /* Add transition for a smoother effect */
}

.tabs a:hover {
  background-color: #ddd;
  color: black;
}

.tabs a.active {
  background-color: #388e3c; /* Slightly darker green for the selected tab */
  color: white;
}

.content {
  padding: 20px;
}

.announcement-bar {
  background-color: #072e09;
  color: #fff;
  padding: 15px;
  text-align: center;
  font-size: 18px;
  margin-bottom: 20px;
}

.announcement-bar p {
  margin: 0;
}

.login-container,
.admin-container {
  padding: 20px;
  background-color: #f5f5dc;
  border-radius: 8px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
}

.login-admin-container {
  display: flex;
  justify-content: space-between;
}

.team-list {
  list-style: none;
  padding: 0;
}

.team-list li {
  margin-bottom: 10px;
}

.team-input {
  margin-right: 10px;
}

/* Add styles for the table in Standings component */
table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

th, td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

th {
  background-color: #072e09;
  color: white;
}

/* Existing styles remain unchanged */

/* Adjust the width of team input boxes in Admin panel */
.team-input {
  width: 100px; /* Adjust the width as needed */
  margin-right: 10px;
}

/* Add styles for the table in Standings component */
table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

th, td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

th {
  background-color: #072e09;
  color: white;
}

/* Add these styles to adjust the width of player input boxes */
.player-input {
  width: 100px; /* Adjust the width as needed */
  margin-right: 10px; /* Optional margin for spacing */
}

